import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import LocalizationContext from '../../localization/context';
import BackToTop from '../backToTop/BackToTop';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import CockpitComponents from '../cockpitComponents/CockpitComponents';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import Navigation from '../navigation/Navigation';
import News from '../news/News';
import Stage from '../stage/Stage';
import Video from '../video/Video';
import * as S from './Layout.styles';

const Layout = () => {
  const { currentPage, siteSettings } = useContext(LocalizationContext);

  return (
    <>
      <Helmet>
        <title>{`${siteSettings.name} ${
          currentPage.metaTitle && '| ' + currentPage.metaTitle
        }`}</title>
        <meta
          name="description"
          content={currentPage.metaDescription || siteSettings.description}
        />
        {!currentPage.indexable && <meta name="robots" content="noindex" />}
        <link rel="icon" href={process.env.REACT_APP_BASE_URL + siteSettings.favIcon.path} />
      </Helmet>
      <Header />
      {currentPage.displayStage && <Stage />}
      <Navigation />
      <S.Main>
        <Container>
          <Breadcrumb />
          {currentPage.displayContact || currentPage.displayNews ? (
            <Row>
              <Col md={8} xs={12}>
                {currentPage.components && (
                  <CockpitComponents components={currentPage.components} />
                )}
              </Col>
              <Col md={4} xs={12}>
                {currentPage.displayNews && <News />}
                {currentPage.displayVideo && (
                  <>
                    <Video
                      src="/assets/videos/FUBIC_Final.mp4"
                      poster="/assets/images/fubic-film-poster.png"
                      headline="FUBIC Film"
                      isYouTube={false}
                    />
                    <Video
                      src="https://youtu.be/mGIVOJ8eQhE"
                      headline="Erhalt statt Abriss"
                      isYouTube={true}
                    />
                    <Video
                      src="https://youtu.be/mEkl1zy65JY"
                      headline="FUBIC under construction"
                      isYouTube={true}
                    />
                  </>
                )}
                {currentPage.displayContact && (
                  <Contact
                    text={currentPage.contact.text}
                    mobile={currentPage.contact.mobile}
                    mail={currentPage.contact.mail}
                    fax={currentPage.contact.fax}
                  />
                )}
              </Col>
            </Row>
          ) : (
            currentPage.components && <CockpitComponents components={currentPage.components} />
          )}
        </Container>
      </S.Main>
      <Container>
        <Footer />
      </Container>
      <BackToTop />
    </>
  );
};

export default Layout;
